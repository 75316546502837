// import cookies from 'react-cookies';
import { toast } from 'react-toastify';
import i18n from 'i18next';
import {
    SIGNIN_INFO_CHANGED,
    SIGNIN_START,
    SIGNIN_SUCCESS,
    SIGNIN_FAIL,
    SIGNIN_FINISH,
    SIGNOUT,
    USER_SELECT_COURSE,
    SIGNUP_OPERATION_START,
    SIGNUP_SUCCESS,
    SIGNUP_OPERATION_FINISH
} from '../types';
import request from '../../api';
import history from '../../history';
import PATHS from '../../Routers/PATHS';
import { userGetAllCourses, userGetSelectedCourseStruct } from '../user section/CoursesActions';
import { noConnection, badReq } from '../../helpers/errors';
import checkIsAdmin from '../../helpers/checkIsAdmin';
import SecurityPreventions from '../../SecurityPreventions';
import { getAllNotifications } from './NotificationActions';
import { ClientJS } from 'clientjs';
import { moveToPath } from '../../helpers/savePath';

const client = new ClientJS();

export const getFingerPrint = async (email = null) => {
    let visitor = null;
    try {
        const geolocation = await (await fetch('https://ipapi.co/json/')).json();

        visitor = {
            "browserName": client.getBrowser(),
            "browserVersion": client.getBrowserVersion(),
            "device": client.getDevice(),
            "deviceType": client.getDeviceType(),
            "deviceVendor": client.getDeviceVendor(),
            "userAgent": client.getUserAgent(),
            "incognito": false,
            "ip": geolocation['ip'],
            "ipLocation": {
                "accuracyRadius": 10,
                "city": {
                    "name": geolocation['city']
                },
                "continent": {
                    "code": geolocation['continent_code'],
                    "name": geolocation['continent_code']
                },
                "country": {
                    "code": geolocation['country'],
                    "name": geolocation['country_name']
                },
                "latitude": geolocation['latitude'],
                "longitude": geolocation['longitude'],
                "timezone": geolocation['timezone']
            },
            "os": client.getOS(),
            "osVersion": client.getOSVersion(),
            //"visitorFound": true,
            "visitorId": email ? client.getCustomFingerprint(email) : client.getFingerprint()
        };
    } catch (err) {
        console.log(err);
        visitor = null;
    } finally {
        return visitor;
    }
};

export const signinInfoChanged = ({ props, value }) => {
    return { type: SIGNIN_INFO_CHANGED, payload: { props, value } };
};

export const signin = (email, password) => async (dispatch, getState) => {
    dispatch({ type: SIGNIN_START });

    try {
        const visitor = await getFingerPrint();
        const notificationToken = getState().notifications.notificationToken;
        const { data } = await request.post('/auth/login', { email, password, visitor, notificationToken });
        if (data) {
            await signinSuccess(dispatch, data);
        } else signinFail(dispatch, i18n.t('SigninFail'));
    } catch (err) {
        if (err.response) {
            if (err.response.data.error && err.response.data.error.code === 'InactiveAccount') history.push(PATHS.LANDING_ACTIVATION.URL);
            else if (err.response.data.error && err.response.data.error.code === 'BlockedAccount') signinFail(dispatch, i18n.t('BlockedAccountText'));
            else signinFail(dispatch, i18n.t('SigninFail'));
        }
        else noConnection();
    } finally {
        dispatch({ type: SIGNIN_FINISH });
    }
};

export const signinGoogleSocialAuth = (authData) => async (dispatch, getState) => {
    dispatch({ type: SIGNIN_START });
    dispatch({ type: SIGNUP_OPERATION_START });

    try {
        const visitor = await getFingerPrint();
        const notificationToken = getState().notifications.notificationToken;
        const { data } = await request.post('/auth/oauth2/google/login', { googleAccToken: authData.access_token, ...authData, visitor, notificationToken });
        if (data) {
            await signinSuccess(dispatch, data);
            dispatch({ type: SIGNUP_SUCCESS });
        } else
            signinFail(dispatch, i18n.t('SigninFail'));
    } catch (err) {
        if (err.response) {
            if (err.response.data.error && err.response.data.error.code === 'InactiveAccount')
                history.push(PATHS.LANDING_ACTIVATION.URL);
            else if (err.response.data.error && err.response.data.error.code === 'BlockedAccount')
                signinFail(dispatch, i18n.t('BlockedAccountText'));
            else
                signinFail(dispatch, i18n.t('SigninFail'));
        }
        else noConnection();
    } finally {
        dispatch({ type: SIGNIN_FINISH });
        dispatch({ type: SIGNUP_OPERATION_FINISH });
    }
};

export const signinAppleSocialAuth = (authData) => async (dispatch, getState) => {
    dispatch({ type: SIGNIN_START });
    dispatch({ type: SIGNUP_OPERATION_START });

    try {
        const visitor = await getFingerPrint();
        const notificationToken = getState().notifications.notificationToken;
        const { data } = await request.post('/auth/oauth2/apple/login', { id_token: authData.id_token, ...authData, visitor, notificationToken });
        if (data) {
            await signinSuccess(dispatch, data);
            dispatch({ type: SIGNUP_SUCCESS });
        } else
            signinFail(dispatch, i18n.t('SigninFail'));
    } catch (err) {
        if (err.response) {
            if (err.response.data.error && err.response.data.error.code === 'InactiveAccount')
                history.push(PATHS.LANDING_ACTIVATION.URL);
            else if (err.response.data.error && err.response.data.error.code === 'BlockedAccount')
                signinFail(dispatch, i18n.t('BlockedAccountText'));
            else
                signinFail(dispatch, i18n.t('SigninFail'));
        }
        else noConnection();
    } finally {
        dispatch({ type: SIGNIN_FINISH });
        dispatch({ type: SIGNUP_OPERATION_FINISH });
    }
};

export const signinSuccess = async (dispatch, user, isCheckAuthed) => {
    await afterStudentSignin(user)(dispatch);
    dispatch({ type: SIGNIN_SUCCESS, payload: user });
    if (!isCheckAuthed) {
        if (moveToPath('buyingPathPref')) return;
        if (moveToPath('examPathPref')) return;
        if (moveToPath('beforeLogoutPathPref')) return;
        const u = checkIsAdmin(user);
        if (u.isAdmin)
            history.push(PATHS.ADMIN_DASHBOARD.URL);
        else
            history.push(PATHS.USER_DASHBOARD.URL);
    }
};

const signinFail = (dispatch, err) => {
    dispatch({ type: SIGNIN_FAIL, payload: err });
};

export const checkAuth = () => async dispatch => {
    try {
        const { data } = await request.get('/auth/is_loggedin');
        signinSuccess(dispatch, data.user || "", true);

    } catch (err) {
        signout()(dispatch);
        if (err.response) badReq();
        else noConnection();
    }
};

export const signout = () => async dispatch => {
    try {
        history.push(PATHS.LANDING_HOME.URL);
        dispatch({ type: SIGNOUT });
        await request.get('/auth/logout');
    } catch (err) {
        if (err.response) badReq();
        else noConnection();
    }
};

const afterStudentSignin = (user) => async (dispatch) => {
    const u = checkIsAdmin(user);
    if (u) getAllNotifications()(dispatch);
    if (u && !u.isAdmin) {
        SecurityPreventions();
        await getUserLastSelectedCourse(dispatch);
    };
};

const getUserLastSelectedCourse = async (dispatch) => {
    try {
        const urlParams = new URLSearchParams(window.location.search);
        const passCode = urlParams.get('passCode');
        let linkCourseId;
        if (passCode) {
            try {
                const { data: { data } } = await request.post(`/student/pass-link/run`, { passCode });
                linkCourseId = data?.examCourseId;
            } catch (error) {
                return toast.error(i18n.t(error.response.data.code), { position: toast.POSITION.BOTTOM_CENTER, theme: "dark" });
            }
        }
        const courses = await userGetAllCourses()(dispatch);
        // check if there is courseId in the url
        linkCourseId = urlParams.get('courseId');
        if (linkCourseId) {
            const selectedCourse = courses.find(c => c.id === +linkCourseId);
            if (selectedCourse) {
                dispatch({ type: USER_SELECT_COURSE, payload: selectedCourse });
                await userGetSelectedCourseStruct(+linkCourseId)(dispatch);
                return;
            }
        }
        // const selectedCourseCookie = cookies.load('coursePref');
        // if (selectedCourseCookie) {
        //     const selectedCourse = courses.find(c => c.id === +selectedCourseCookie);
        //     if (selectedCourse) {
        //         dispatch({ type: USER_SELECT_COURSE, payload: selectedCourse });
        //         await userGetSelectedCourseStruct(selectedCourse.id)(dispatch);
        //     }
        //     else cookies.remove('coursePref');
        // }
    } catch (err) {
        if (err.response) badReq();
        else noConnection();
    }
};
