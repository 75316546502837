import React, { useEffect, useState } from 'react';
import { Icon, Label, Segment } from 'semantic-ui-react';
import { ErrorSegment } from '..';
import request from '../../api';
import { Theme } from '../../res';


const CertCoursesList = ({ certId, selectedCourse, setSelctedCourse }) => {
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const getCoursesList = async () => {
            setLoading(true);
            try {
                const { data: { data: courses } } = await request(`/public/supscription/package/category?certificateId=${certId}`);
                setCourses(courses);
                courses.length && !selectedCourse && setSelctedCourse(courses[0].categoryId);
            } catch (error) {
                setError(error);
            }
            finally {
                setLoading(false);
            }
        };
        if (certId) getCoursesList();
        //eslint-disable-next-line
    }, [certId, setCourses, setLoading, setError]);

    if (error) return <ErrorSegment />;
    // if (!courses.length) return <Segment basic textAlign='center' loading={loading}><ErrorSegment icon='wait' text={i18next.t('ComingSoon')} /></Segment>;

    return (
        <Segment basic>
            {/* <Header style={f_16_700_text}>{t('Categories')}</Header> */}
            <Label.Group>
                {
                    courses.map(c => <Label
                        as='a'
                        key={c.categoryId}
                        style={{ color: Theme.colors.WHITE, backgroundColor: selectedCourse === c.categoryId ? Theme.colors.PRIMARY_50 : Theme.colors.GREY_30 }}
                        onClick={() => setSelctedCourse(c.categoryId)}
                    >
                        {selectedCourse === c.categoryId && <Icon name='check circle' />}
                        {c.name}
                    </Label>)
                }
            </Label.Group>
        </Segment>
    );
};

export default CertCoursesList;
