import React, { useState } from 'react';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';
import { Divider, Header } from 'semantic-ui-react';
import { Styles } from '../../res';

import avatarImage from '../../res/images/avatar.svg';
import hatImage from '../../res/images/hat.svg';

const { f_20_700_text, f_12_400_text } = Styles;
const LIMIT = 250;

const SuccessStoryCard = ({ name, quote, img }) => {
    const { t } = useTranslation();
    const isLong = quote.length > LIMIT;
    const [isExpanded, setIsExpanded] = useState(isLong ? false : true);
    const shortText = quote.slice(0, LIMIT) + "... ";

    const renderText = () => {
        if (!isLong) return <p style={f_12_400_text}>{quote}</p>;
        if (isExpanded) return <p style={f_12_400_text}>{quote + " "} <span className='tw-cursor-pointer tw-text-PRIMARY_50 tw-font-bold' onClick={() => setIsExpanded(false)}>{t("Hide")}</span></p>;
        return <p style={f_12_400_text}>{shortText} <span className='tw-cursor-pointer tw-text-PRIMARY_50 tw-font-bold' onClick={() => setIsExpanded(true)}>{t("ReadMore")}</span></p>;
    };

    return (
        <div className='tw-m-10'>
            <div className='tw-flex tw-flex-row tw-gap-5'>
                <div className='tw-relative'>
                    <Avatar src={img || avatarImage} round size={70} className='tw-border' />
                    <img src={hatImage} alt="hat" className='tw-absolute tw-w-8 tw-top-12 -tw-end-3' />
                </div>
                <div>
                    <Header style={f_20_700_text}>{name}</Header>
                    {renderText()}
                </div>
            </div>
            <Divider />
        </div>
    );
};

export default SuccessStoryCard;