import i18n from 'i18next';
import PATHS from '../Routers/PATHS';

export const getHeaderBarData = () => {
    return [
        { id: 1, name: i18n.t('Home'), link: PATHS.LANDING_HOME.URL },
        { id: 2, name: i18n.t('CertificatesCurriculums'), link: PATHS.LANDING_CERTIFICATIONS.URL },
        { id: 3, name: i18n.t('Courses'), link: PATHS.LANDING_COURSES.URL },
        // { name: i18n.t('WhyUs'), link: '/whyus' },
        // { name: i18n.t('Staff'), link: '/staff' },
        { id: 6, name: i18n.t('AboutUs'), link: PATHS.LANDING_CONTACTUS.URL },
        { id: 7, name: i18n.t('HonorRoll'), link: PATHS.LANDING_HONOR.URL },
        { id: 8, name: i18n.t('Promises'), link: PATHS.LANDING_PROMISES.URL },
        // { id: 9, name: i18n.t('ForBusiness'), link: PATHS.LANDING_COMPANIES.URL },
        { id: 10, name: i18n.t('Blogs'), link: `${PATHS.LANDING_BLOGS.URL}?tab=BLOGS` },
        { id: 11, name: i18n.t('Events'), link: `${PATHS.LANDING_BLOGS.URL}?tab=EVENTS` },
    ];
};